import { employeeinfo } from "../commonDynmicForms/employee_info";
import { FillableBy } from "./form_enums";
import { getSignatures } from "../commonDynmicForms/signature";
import { table_fields} from "../commonDynmicForms/table_fields"
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { constants } from "../constants";

export default {
    fillable_by : FillableBy.Both,
    title:
        "Clinical Competency Checklist for CardioQuip Heater-Cooler Device",
    sections: [
        // {...employeeinfo},
        {
            title: "Device Setup",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Verifies that the device is clean and free of damage before use.",
                "Inspects power cord and connections for integrity.",
                "Assembles tubing and connectors correctly based on clinical needs.",
                "Fills the water reservoir to the correct level using sterile or distilled water.",
                "Selects appropriate temperature settings based on patient requirements."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Operation",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Turns on the device and ensures all indicator lights are functioning.",
                "Adjusts temperature controls accurately to achieve desired water temperature.",
                "Monitors and maintains the device's temperature throughout the procedure.",
                "Ensures tubing connections to the oxygenator or cardioplegia circuit are secure and leak-free.",
                "Troubleshoots and resolves any operational alarms during use.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Safety and Infection Control",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Ensures that only sterile or distilled water is used in the reservoir.",
                "Checks and replaces filters as per manufacturer guidelines.",
                "Adheres to manufacturer’s cleaning and disinfection protocols.",
                "Documents all cleaning and disinfection procedures accurately.",
                "Ensures the heater-cooler device is positioned to minimize potential aerosolization risks."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Troubleshooting and Emergency Procedures",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Identifies and interprets device alarms.",
                "Responds to high or low temperature alarms appropriately.",
                "Implements backup plans in case of device failure (e.g., switching to an alternate device).",
                "Communicates device-related issues to the surgical team promptly.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Documentation",
            type: "table",
            heads:constants.tableHeads,
            rows: [
                "Records heater-cooler device temperatures at regular intervals during the procedure.",
                "Logs any device malfunctions or alarms in the patient record and incident report (if applicable).",
                "Completes maintenance and usage logs for the device after the procedure.",
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {...evaluationCheckList},
        {
            title: "Signatures",
            type: "signature",
            fields: getSignatures(FillableBy.Both),
        },
    ],
}